.review-container {
    width: 40%;
    position: absolute;
    background: white;
    z-index: 102;
    margin: 16px auto;
    padding: 24px;
    border: 1px solid lightgrey;
    border-radius: 8px;
    font-size: 16px;
}

#prog {
    width: 100%;
    height: 7px;
    background: navy;
    border-radius: 12px;
    margin: 6px 0 10px;
}



.review-container h3 {
    color: #3d3a3a;
}

.backArrow {
    display: flex;
    align-items: center;
}

.backArrow > :first-child {
    cursor: pointer;
    width: 20px;
    margin-right: 8px;
    padding: 5px;
}

.backArrow > :first-child:hover {
    background: rgb(238, 241, 254);
    border-radius: 5px;
}

.review-container h2 {
    font-size: 20px;
    margin: 15px 0;
}

.review-container h4 {
    font-size: 16px;
    color: grey;
}

.contactInfo,
.questions {
    margin: 10px 0 20px;
    padding: 0 10px 10px 10px;
    border: 1px solid lightgrey;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.contactInfo > div,
.questions > div {
    border-bottom: 1px solid lightgrey;
    padding: 10px;
}

.contactInfo p,
.questions p {
    color: #706e6e;
}

.contactInfo h3,
.questions h3 {
    font-size: 17px;
    margin-bottom: 20px !important;
}

.contactInfo > div:last-child,
.questions > div:last-child {
    border: none;
}

small {
    color: grey;
}

span {
    cursor: pointer;
    color: rgb(22, 64, 129);
}

.resume {
    margin: 10px 0 20px;
    height: 70px;
    border: 1px solid lightgrey;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.resume > :first-child {
    width: 30px;
    height: 30px;
    color: #ee1b1b;
    margin-right: 16px;
}

.resume > h3 {
    color: rgb(22, 64, 129);
    font-size: 16px;
    cursor: pointer;
}

.actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* margin-top: 50px;
    margin-bottom: 50px; */
}

.actions > :first-child {
    color: rgb(22, 64, 129);
    margin-right: 30px;
    cursor: pointer;
}

.actions > button {
    width: 220px;
    background: rgb(22, 64, 129);
    padding: 13px;
    font-weight: bold;
    font-size: 15px;
    border-radius: 5px;
    border: none;
    color: white;
    cursor: pointer;
}

.textCenter {
    text-align: center;
}


/* diaogue */
.dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialog-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
}

.dialog-content h3 {
    margin-bottom: 10px;
}

.dialog-content p {
    margin-bottom: 20px;
}

.dialog-content button {
    padding: 10px 20px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
