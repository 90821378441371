.Loading h2 {
  color: rgb(248, 248, 245);
}

.FullscreenOverlay,
.Loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.FullScreenImage {
  padding: 5px;
  box-shadow: rgba(47, 91, 116, 0.603) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 5px;
  display: block;
  width: 600px;
  cursor: pointer;
  object-fit: contain;
}
.FullScreenVideo {
  padding: 5px;
  box-shadow: rgba(47, 91, 116, 0.603) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 15px;
  display: block;
  width: 80%;
  /* height: 600px; */
  cursor: pointer;
  object-fit: contain;
}

.FullScreenDoc {
  padding: 5px;
  box-shadow: rgba(47, 91, 116, 0.603) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 5px;
  display: block;
  width: 80%;
  height: 900px;
  cursor: pointer;
  object-fit: contain;
}

/* Center the FullScreenImageContainer */
@media (max-width: 768px) {
  .FullScreenImageContainer {
    max-width: 90%;
    max-height: 90%;
  }
}
