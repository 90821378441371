.success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    min-height: 90vh;
    background-color: #f5f5f500;
    padding: 24px;
}

.success-paper {
    padding: 32px;
    text-align: center;
    /* background-color: bisque; */
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.success-img {
    width: 100%;
    max-width: 300px;
    margin-bottom: 16px;
}

.success-button {
    margin-top: 16px;
}

.success-link {
    text-decoration: none;
    color: inherit;
}

.information {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.information-box {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.icon {
    font-size: 3rem;
}
