.common-container{
    width: 66%;
    margin: auto;
    max-width: 500px;

}

/* .progressBar {
    width: 100%;
    height: 7px;
    background-color: navy;
    border-radius: 12px;
    padding: 0;
} */

.step-header {
    display: flex;
    align-items: center;
}

.step-header #arrow {
    cursor: pointer;
    width: 20px;
    margin-right: 8px;
    padding: 5px;
}

.step-header #arrow:hover {
    background: rgb(238, 241, 254);
    border-radius: 5px;
}

 /* h4 {
    margin: 6px 0 25px;
} */

.common-container h4 span,
.common-container h5 span {
    font-weight: normal;
}

.common-container form {
    width: 100%;
    border: 1px solid wheat;
    padding: 21px;
    border-radius: 8px;
    margin-top: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
    display: grid;
    align-items: center;
    margin-top: 10px;
}

.common-container form input,
.common-container textarea {
    width: 90%;
    display: block;
    padding: 11px;
    border: 1px solid rgba(0, 0, 0, .3);
    border-radius: 7px;
    margin: 8px 0 28px;
    font-size: 17px;
    outline: none;
}

.common-container form label {
    font-weight: bold;
}

.document-option {
    display: flex;
    align-items: center;
    border: 1px solid rgba(202, 207, 214, 0.767);
    padding: 10px;
    margin: 10px;
}

.document-option section {
    margin-left: 8px;
}

/*  .document-option:nth-child(5) {
    display: flex;
    align-items: center;
} */

.document-option svg {
    width: 30px;
    height: 30px;
    color: grey;
    margin-right: 8px;
}

.document-option section h5 {
    color: rgb(22, 64, 129);
}

.document-option section p {
    font-size: 14px;
    text-align: left;
}

.document-option .tick-icon {
    width: 20px;
    height: 20px;
    color: white;
    background: rgb(22, 64, 129);
    border-radius: 50%;
    margin-left: auto;
}

.form-actions {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* margin-top: 50px; */
    /* margin-bottom: 50px; */
}


.form-actions h5,
.exit-link,
.return-link {
    color: rgb(22, 64, 129);
    margin-right: 30px;
    cursor: pointer;
}

.form-actions button {
    width: 220px;
    background: rgb(22, 64, 129);
    padding: 13px;
    font-weight: bold;
    font-size: 15px;
    border-radius: 5px;
    border: none;
    color: white;
    cursor: pointer;
}

.issue {
    text-align: center;
}

.issue span {
    color: rgb(22, 64, 129);
    cursor: pointer;
}