@media screen and (max-width: 1200px) {
    .Left-container {
        width: 100% !important;
    }

    .right-container {
        display: none;
    }

    img {
        display: none;
    }

    .middle-container {
        width: 90%;
        margin-top: 30px;
    }
}

.middle-container {
    position: relative;
    width: 77%;
    border-top: 1px solid rgba(0, 0, 0, .3);
    margin: auto;
    padding-top: 20px;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 30px;
}

.Left-container {
    width: 41.5%;
}

.pages {
    width: 100%;
}

.pages p {
    font-weight: normal;
    font-size: .75rem;
    line-height: 1rem;
    color: #6f6f6f;
}

.pages>div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: .8rem !important;
}

.sort span:first-of-type {
    color: black;
    font-weight: 600;
}

.sort span:last-of-type {
    color: rgb(37, 87, 167);
    font-weight: 600;
}

.grey {
    color: black !important;
    cursor: pointer;
}

.blue {
    color: rgb(37, 87, 167) !important;
    cursor: pointer;
}


.jobs-container {
    width: 100%;
    height: auto;
}

/*paginate*/

.paginateBttns {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    width: 90%;
    padding: 0;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 20px;
}

.paginateBttns a {
    font-weight: 500;
    border-radius: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    margin-left: 12px;
    font-size: 18px;
    border-collapse: separate;
    box-sizing: border-box;
    cursor: pointer;
    font-stretch: 100%;
    line-height: 23.4;
    background: #ececec;
    color: #2d2d2d;
}

.paginateDisabled {
    display: none !important;
}

.paginateBttns a:hover {
    background-color: #d0caca;
    color: black;
}



.paginateActive a {
    background-color: rgb(89, 89, 89) !important;
    color: white !important;
    font-weight: 700 !important;
}

.previousBn,
.nextBn {
    font-weight: 900 !important;
}


.hideit {
    display: none;
}

.right-container {
    position: sticky !important;
    top: 100px !important;
    width: 52%;
    height: 600px;
    height: 600px;

}